.wrapper {
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

@media screen and (min-width: 767px) {
  .signInStatus {
    margin-left: 250px ;
    transition: all 0.5s;
  }
  .active-sidebar-toggle {
    margin-left: 88px !important;
    transition: all 0.5s;
  }
}
