.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.width-100 {
  width: 100%;
}

.pt-8 {
  padding-top: 8px !important;
}

.btn-base {
  background: $color-blue-500;
  color: white;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-width: 0;
  width: 100%;
}

.text-color-base {
  color: $color-blue-500;
}

.close {
  float: right;
  text-decoration: unset;
}

.btn-social {
  padding: 0;
  border: 0;
  width: 44px;
  background: none;

  img {
    width: 100%;
  }

  &:hover {
    background: none;
  }
}

.group-btn {
  display: flex;
  justify-content: flex-end;
}

.mb-0 {
  margin-bottom: 0;
}

.btn-link {
  text-decoration: none;
}

.line-center {
  position: relative;

  &:after,
  &:before {
    background-color: $color-grey-300;
    position: absolute;
    content: "";
    height: 1px;
    top: 50%;
    left: 0;
    width: calc(50% - 32px);
  }

  &:before {
    right: 0;
    left: unset;
  }
}

.background-color-base {
  background-color: $color-blue-25;
}

.w-auto {
  width: auto !important;
}

.custom-input {
  border: 1px solid $color-grey-200;
  border-radius: 8px;
  background: white;

  input {
    border: 0;
  }
}

.btn-redirect-login {
  background: $color-blue-600;
  box-shadow: rgba(16, 24, 40, 0.05);
  border: 0;
  width: 88px;
  height: 40px;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.title-header-nav {
  margin-top: 1rem;
  font-size: 26px;
  text-align: center;
  color: white;
  text-decoration: none;
}

a.link-header-nav {
  text-decoration: unset;
}

.invalid-feedback {
  display: block !important;
}

.btn-base {
  background: $color-blue-600;
  color: $color-white;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-width: 0;
  width: 100%;
}

.text-color-base {
  color: $color-blue-500;
}

.close {
  float: right;
  text-decoration: unset;
}

.btn-social {
  padding: 0;
  border: 0;
  width: 44px;
  background: none;

  img {
    width: 100%;
  }

  &:hover {
    background: none;
  }
}

.group-btn {
  display: flex;
  justify-content: space-between;
}

.mb-0 {
  margin-bottom: 0;
}

.btn-link {
  text-decoration: none;
}

.background-color-base {
  background-color: $color-blue-25;
}

.text-sm {
  font-size: $text-sm;
}

.text-display-xs {
  font-size: $text-display-xs;
}

.icon-register {
  height: 44px;
  background: $color-white;
  color: green;
  border: 1px solid $color-grey-300;
  box-shadow: rgba(16, 24, 40, 0.05);
}

.custom-input {
  border: 1px solid $color-grey-200;
  border-radius: 8px;
  background: $color-white;

  input {
    border: 0;
  }
}

.body {
  background: $color-blue-25;
  color: $color-grey-700;
  word-break: break-word;
  min-height: 100vh !important;
}

.btn-notification {
  padding: 0;
  background: $color-blue-25;
  border: 0;
  color: $color-blue-600;

  &:hover {
    background: $color-blue-25;
    color: $color-blue-600;
  }

  &::before {
    display: none !important;
  }
}

.btn-notification-action {
  border: none;
  padding: 0;
  background: inherit;

  &::before {
    display: none !important;
  }
}

.min-vh-90 {
  min-height: 90vh !important;
}

.btn-change-username {
  background: $color-blue-600;
  color: $color-white;
  border: 0;
}

.btn-cancel {
  background: $color-grey;
  border: 0;
}

.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  $color-white-space: break-word;
  line-clamp: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.btn-common {
  background-color: $color-blue-600;
  color: $color-white;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  min-width: fit-content;

  &.btn-common--disabled {
    background-color: $color-grey-500;
    pointer-events: none;
  }
}

.card-no-border {
  border: 0 !important;
  border-radius: 10px !important;
}

.open-menu {
  overflow-y: hidden;
}

.common-header {
  margin-bottom: 20px;
  background: $color-white;
  padding: 16px;
  border-radius: 10px;
  font-size: 16px;
  position: relative;
  z-index: 1;

  &.common-header--blue-25 {
    background-color: $color-blue-25;

    button,
    img,
    span {
      background-color: $color-blue-25 !important;
    }
  }
}

.btn-header {
  color: $color-grey-500;
  text-decoration: none;
  background: $color-white;
  border: 0;
  padding: 0;
  @include hover_common();

  &:hover {
    color: $color-grey-500;
  }
}

.text-page-title {
  font: normal normal 700 18px/32px $font-sf-pro;
  margin-bottom: 0;
}

ul.ngx-pagination {
  display: flex;

  li {
    background: $color-white;

    &,
    a {
      border-radius: 4px;
      height: 38px;
      min-width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }

    &.current {
      background: $color-blue-600;
    }

    &.pagination-next {
      &::after,
      a::after {
        margin-left: 0;
        margin-bottom: 2px;
      }
    }

    &.pagination-previous {
      &::before,
      a::before {
        margin-right: 0;
        margin-bottom: 2px;
      }
    }
  }
}

.check-all-color {
  background-color: $color-blue-600;
  color: $color-white;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  min-width: fit-content;

  &.active {
    background-color: white !important;
    color: $color-blue-600 !important;
    padding: 7px 7px;
    border-radius: 8px;
    border: 1px solid $color-blue-600;
  }
}

.not-check-all-color {
  background: $color-white;
  color: $color-blue-600;
  border: 1px solid $color-blue-600;
  padding: 7px;
  border-radius: 8px;
  min-width: fit-content;
}

.select-date .date-picker .ng-pristine {
  border: none !important;
}

.date-picker {
  border: none !important;

  .ng-star-inserted {
    border: none !important;
  }
}

.date-picker-input-icon {
  cursor: pointer !important;
}

.picker.show .input-group {
  box-shadow: none !important;
  border: none !important;
}

.no-border {
  border: none !important;
  background-color: $color-white !important;
}

.custom-container {
  max-width: 90%;
  margin: auto;
}

.custom__body {
  margin-top: -25px;
}

.number_filter {
  margin-bottom: 16px;
  margin-left: 8px;
}

@media screen and (max-width: 765px) {
  .common-header {
    justify-content: space-between;
    align-items: center;
  }

  .custom-container {
    max-width: 100%;
  }
}

.form-select-date .picker-input-group .input-group-text {
  background-color: var(--cui-input-group-addon-bg, #d8dbe0);
}

.bshadow > img {
  width: 400px;
}

.color-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.check-box-color:checked ~ .color-icon {
  background-color: white !important;
  border: 1px solid $color-blue-500 !important;
  padding: 1px;

  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: var(--color);
    border-radius: 50%;
    border: var(--border-color);
  }
}

.select-date {
  border: 1px solid $color-blue-100;
  border-radius: 8px;
  padding: 8px;
  width: 100%;

  .input-group-text {
    border: none !important;
  }
}

input,
select,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--cui-input-border-color, $color-grey-medium);
  }
}

.box-select-date {
  input {
    border: none !important;

    &:focus {
      border: none !important;
    }
  }
}

.form_item {
  &--title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &--content {
    padding: 0 16px;
  }

  &--subtitle {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }

  &--checkbox_group {
    .checkbox_group--inline {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.form-label {
  cursor: pointer;
}

.popover-body {
  word-break: break-all !important;
}

.gap-10px {
  gap: 10px;
}

.mt-30px {
  margin-top: 30px;
}

// common for list pages
.box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-width-320 {
    display: block;
  }
}

.btn-search {
  border: 0;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  min-width: 66px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
}

.btn-title {
  border: 0;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  min-width: 88px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 16px;
  &:hover {
    color: $color-white;
  }

  &-export {
    color: $color-blue-500;
    background-color: $color-blue-100;

    &::before {
      content: "";
      background: url("/assets/svg/export.svg");
      width: 24px;
      height: 24px;
      display: block;
    }

    &:hover {
      color: $color-blue-500;
    }
  }

  &-create {
    color: $color-white;
    background-color: $color-blue-600;

    &::before {
      content: "";
      background: url("/assets/svg/add-square.svg");
      width: 24px;
      height: 24px;
      display: block;
    }
  }

  &-disabled {
    pointer-events: none;
    background-color: $color-button-disabled;
  }
}

.search__date {
  display: flex !important;

  @include sp {
    display: block !important;

    .select-date {
      margin-bottom: 8px;
    }
  }
}

.search_color {
  &--first {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;

    @include pc {
      align-items: center;
    }

    @include sp {
      flex-direction: column;
      gap: 10px;
    }

    .search_all {
      display: grid;
      grid-template-columns: 30% auto;
      justify-content: flex-start;
      gap: 15px;
    }

    .color_list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  .color_item {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  &--two {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.button-collapse {
  width: 60px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  .caret-down {
    transform: rotate(0);
    transition: 400ms ease-out;

    &-open {
      transform: rotate(-180deg);
    }
  }

  .add-square {
    color: #667085;
  }
}

#querySearch {
  border: none !important;
  outline: none !important;
}

.col-border {
  border: 1px solid $color-blue-100;
  border-radius: 8px;
  padding: 8px;
}

// end common for list pages
.c-picker {
  .time-picker {
    .dropdown-menu {
      min-width: fit-content !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-submit {
  background-color: $color-blue-500 !important;
}

.back-btn {
  background-color: $color-white;
  color: $color-blue-500 !important;
  border: 1px solid $color-blue-500 !important;
  border-radius: 6px;
  margin-right: 1rem;
  padding: 0 12px;
}

.breadcrumb-item {
  a {
    text-decoration: none !important;
    color: $color-blue-500;

    &:hover {
      color: $color-blue-300;
    }
  }
}
