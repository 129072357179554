/* Color styles */

$color-grey-25: #FCFCFD;
$color-grey-50: #F9FAFB;
$color-grey-100: #F2F4F7;
$color-grey-200: #EAECF0;
$color-grey-300: #D0D5DD;
$color-grey-400: #98A2B3;
$color-grey-500: #667085;
$color-grey-600: #475467;
$color-grey-700: #344054;
$color-grey-800: #1D2939;
$color-grey-900: #101828;
$color-orange-25: #FFFAF5;
$color-orange-50: #FFF6ED;
$color-orange-100: #FFEAD5;
$color-orange-200: #FDDCAB;
$color-orange-300: #FEB273;
$color-orange-400: #FD853A;
$color-orange-500: #FB6514;
$color-orange-600: #EC4A0A;
$color-orange-700: #C4320A;
$color-orange-800: #9C2A10;
$color-orange-900: #7E2410;
$color-blue-25: #F5FBFF;
$color-blue-50: #F0F9FF;
$color-blue-100: #E0F2FE;
$color-blue-200: #B9E6FE;
$color-blue-300: #7CD4FD;
$color-blue-400: #36BFFA;
$color-blue-500: #0BA5EC;
$color-blue-600: #0086C9;
$color-blue-700: #026AA2;
$color-blue-800: #065986;
$color-blue-900: #0B4A6F;
$color-black: #000000;
$color-white: #fff;
$color-blue: #2E90FA;
$color-dark-blue: #175CD3;
$color-grey: #8a93a2;
$color-input-disabled: #d8dbe0;
$color-button-disabled: #697588;
$color-close-modal-button: #F2F4F7;
$color-grey-medium: #b1b7c1;
$color-orange-hard: #FB6514;
$color-pink: #F04438;
$color-pink-100: #FECDCA;
$dark-blue: #0e2431;
$color-gray: #A3A9B6;
$color-light-gray: #e9e9e9;
$color-btn-active: #0ba5ec;
$color-scroll: #c0c4cc;
/* Text size styles */
$text-display-xl: 64px;
$text-display-md: 36px;
$text-display-sm: 30px;
$text-display-xs: 24px;
$text-lg: 18px;
$text-md: 16px;
$text-sm: 14px;
$text-xs: 12px;

/* Width */
$width-max-tablet: 769px;
$width-min-tablet: 769px;

/* Font family */
$font-sf-pro: 'SF Pro Display', serif;
$font-family: "Noto Sans JP", sans-serif;
$max-width-sp: 767px;

// bool
$enable-ltr: true;
$enable-rtl: true;

$sidebar-color: #fff;
$sidebar-text-color: #707070;
