@mixin hover_common {
  transition: 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

@mixin pc {
  @media screen and (min-width: (767px + 1)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin min-width-461 {
  @media screen and (min-width: 461px) {
    @content;
  }
}

@mixin max-width-320 {
  @media screen and (max-width: 320px + 1) {
    @content;
  }
}
